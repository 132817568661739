import { autoinject } from 'aurelia-framework';
import { StateApi } from 'services/state-api';
import {
    AddFileExclusionRequest,
    AddProcessExclusionRequest,
    EdrApiClient,
    GetExclusionsResponse,
    RemoveFileExclusionRequest,
    RemoveProcessExclusionRequest,
    ValidateFileExclusionRequest,
    ValidateProcessExclusionRequest
} from 'services/cyber-api';
import { Toast } from 'utilities/toast';
import Swal from 'sweetalert2';
import { ListView, ListViewValidation } from './components/list-view/list-view';

@autoinject
export class EdrExclusionList {

    constructor(
        private state: StateApi,
        private edrApi: EdrApiClient
    ) {
    }

    private excludedFilesListView: ListView;
    private excludedProcessesListView: ListView;

    private profile: GetExclusionsResponse = undefined;
    private loading = true;
    private updatingPolicyState = false;

    private processValidation: ListViewValidation[] | undefined = undefined;

    private fileValidation: ListViewValidation[] | undefined = undefined;

    private async attached(): Promise<void> {
        await this.loadPolicy();
    }

    private async loadPolicy(): Promise<void> {
        this.loading = true;
        this.profile = await this.edrApi.exclusions(this.state.company());
        this.loading = false;
    }

    private async validateProcess(inputValue: string): Promise<void> {
        if (inputValue === '' || inputValue === null || inputValue === undefined) {
            this.processValidation = [{
                message: 'Please enter a valid name.',
                type: 'error'
            }];
        } else {
            try {
                await this.edrApi.validateProcessExclusion(this.state.company(),
                    new ValidateProcessExclusionRequest({ process: inputValue }));
                this.processValidation = undefined;
            } catch (errors) {
                if (errors && errors.length > 0) {
                    this.processValidation = errors.map((error) => {
                        return {
                            message: error,
                            type: 'error'
                        };
                    });
                } else {
                    this.processValidation = [{
                        message: `Something went wrong. Please try again.`,
                        type: 'error'
                    }];
                }
            }
        }
    }

    private async validateFile(inputValue: string): Promise<void> {
        if (inputValue === '' || inputValue === null || inputValue === undefined) {
            this.fileValidation = [{
                message: 'Please enter a valid name.',
                type: 'error'
            }];
        } else {
            try {
                await this.edrApi.validateFileExclusion(this.state.company(),
                    new ValidateFileExclusionRequest({ file: inputValue }));
                this.fileValidation = undefined;
            } catch (errors) {
                if (errors && errors.length > 0) {
                    this.fileValidation = errors.map((error) => {
                        return {
                            message: error,
                            type: 'error'
                        };
                    });
                } else {
                    this.fileValidation = [{
                        message: `Something went wrong. Please try again.`,
                        type: 'error'
                    }];
                }
            }
        }
    }

    private async addProcess(inputValue: string): Promise<boolean> {
        if (inputValue === '' || inputValue === null || inputValue === undefined) {
            this.processValidation = [{
                message: 'Please enter a valid name.',
                type: 'error'
            }];
            return false;
        } else {
            this.excludedProcessesListView.resetValidation();
        }

        const addProcessExclusionRequest = new AddProcessExclusionRequest({
            policyId: this.profile.policyId,
            process: inputValue
        });

        try {
            await this.edrApi.addProcessExclusion(this.state.company(), addProcessExclusionRequest);
        } catch (errors) {
            if (errors && errors.length > 0) {
                this.processValidation = errors.map((error) => {
                    return {
                        message: error,
                        type: 'error'
                    };
                });
                return;
            } else {
                this.processValidation = [{
                    message: `Something went wrong. Please try again.`,
                    type: 'error'
                }];
                return;
            }
        }

        await this.loadPolicy();
        return true;
    }

    private async addFile(inputValue: string): Promise<boolean> {
        if (inputValue === '' || inputValue === null || inputValue === undefined) {
            this.fileValidation = [{
                message: 'Please enter a valid name.',
                type: 'error'
            }];
            return false;
        } else {
            this.excludedFilesListView.resetValidation();
        }

        const addFileExclusionRequest = new AddFileExclusionRequest({
            policyId: this.profile.policyId,
            file: inputValue
        });

        try {
            await this.edrApi.addFileExclusion(this.state.company(), addFileExclusionRequest);
        } catch (errors) {
            if (errors && errors.length > 0) {
                this.fileValidation = errors.map((error) => {
                    return {
                        message: error,
                        type: 'error'
                    };
                });
                return;
            } else {
                this.fileValidation = [{
                    message: `Something went wrong. Please try again.`,
                    type: 'error'
                }];
                return;
            }
        }

        await this.loadPolicy();
        return true;
    }

    private async removeProcess(item: string): Promise<void> {
        const response = await Swal.fire({
            title: 'Remove process?',
            html: `Are you sure you want to remove ${item}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No, cancel',
            confirmButtonText: 'Yes, remove process',
        });
        if (response.value) {
            const removeProcessExclusionRequest = new RemoveProcessExclusionRequest({
                policyId: this.profile.policyId,
                process: item
            });
            try {
                await this.edrApi.removeProcessExclusion(this.state.company(), removeProcessExclusionRequest);
            } catch (error) {
                // todo: handle error
                Toast.error(`Something went wrong. Please try again.`);
                return;
            }
            Toast.success(`The process "${item}" has been removed successfully`);
            await this.loadPolicy();
        }
    }

    private async removeFile(item: string): Promise<void> {
        const response = await Swal.fire({
            title: 'Remove file?',
            html: `Are you sure you want to remove ${item}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No, cancel',
            confirmButtonText: 'Yes, remove file',
        });
        if (response.value) {
            const removeFileExclusionRequest = new RemoveFileExclusionRequest({
                policyId: this.profile.policyId,
                file: item
            });
            try {
                await this.edrApi.removeFileExclusion(this.state.company(), removeFileExclusionRequest);
            } catch (error) {
                // todo: handle error
                Toast.error(`Something went wrong. Please try again.`);
                return;
            }

            Toast.success(`The file "${item}" has been removed successfully`);
            await this.loadPolicy();
        }
    }

    private async toggleActiveState(): Promise<void> {
        this.updatingPolicyState = true;

        // Activate or deactivate the policy based on the current enabled state
        if (this.profile.policyEnabled)
            await this.edrApi.deactivatePolicy(this.profile.policyId, this.state.company());
        else
            await this.edrApi.activatePolicy(this.profile.policyId, this.state.company());

        // Retrieve the updated policy with the new enabled state
        this.profile = await this.edrApi.exclusions(this.state.company());

        this.updatingPolicyState = false;
    }
}
